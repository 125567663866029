import React from 'react';
import { Column, Container, Row } from '../../components/Grid';
import Layout from '../../components/Layout';
import Resource from '../../components/Resource';
import Section from '../../components/Section';

const CareerProfiles = () => {
  return (
    <Layout title="Career Profiles">
      <Section id="career-profiles" className={'pt-1'}>
        <Container>
          <Row>
            <h2>How STEM Careers Improve Indoor Climate</h2>
            <p>
              Take students inside the world of Trane Technologies, where
              employees across various fields work to create comfortable,
              energy-efficient indoor environments. Check out a series of
              interactive career profiles featuring videos and companion student
              activations.
            </p>
          </Row>
        </Container>
      </Section>
      <Section>
        <Container>
          <Row>
            <Column size={4}>
              <Resource
                img="STEM-image-CP-HVAC.jpg"
                subject="HVAC Technician"
                title="Meet Earnell Newman"
                description="Explore the everyday troubleshooting and preventative maintenance that ensures indoor environments maintain safe and healthy air quality."
                actions={[
                  {
                    label: 'Learn More',
                    link: '/career-profiles/hvac-tech',
                  },
                  {
                    label: 'Supplemental Resources',
                    dropdown: true,
                    actions: [
                      {
                        label: 'Career Profiles',
                        download:
                          '/pdfs/SustainableFutures-CareerProfile-HVACTechnician.pdf',
                        asLink: true,
                      },
                      {
                        label: 'Student Activation',
                        download:
                          '/pdfs/SustainableFutures-CareerActivation-HVACTechnician.pdf',
                        asLink: true,
                      },
                    ],
                  },
                ]}
              />
            </Column>
            <Column size={4}>
              <Resource
                img="STEM-image-CP-DirInnov.jpg"
                subject="Director of Innovation"
                title="Meet Jason Tilghman"
                description="Look ahead to the future of HVAC technology and learn how innovations are embraced by companies to maximize efficiency and success."
                actions={[
                  {
                    label: 'Learn More',
                    link: '/career-profiles/director-of-innovation',
                  },
                  {
                    label: 'Supplemental Resources',
                    dropdown: true,
                    actions: [
                      {
                        label: 'Career Profiles',
                        download:
                          '/pdfs/SustainableFutures-CareerProfile-DirInnovation.pdf',
                        asLink: true,
                      },
                      {
                        label: 'Student Activation',
                        download:
                          '/pdfs/SustainableFutures-CareerActivation-DirInnovation.pdf',
                        asLink: true,
                      },
                    ],
                  },
                ]}
              />
            </Column>
            <Column size={4}>
              <Resource
                img="STEM-image-CP-ProdDevEngnr.jpg"
                subject="Product Development Engineer"
                title="Meet Ana Llanos"
                description="Dive into the dynamic teamwork that goes into the product development lifecycle with an expert in collaboration and problem-solving."
                actions={[
                  {
                    label: 'Learn More',
                    link: '/career-profiles/product-development-engineer',
                  },
                  {
                    label: 'Supplemental Resources',
                    dropdown: true,
                    actions: [
                      {
                        label: 'Career Profiles',
                        download:
                          '/pdfs/SustainableFutures-CareerProfile-ProdDevEng.pdf',
                        asLink: true,
                      },
                      {
                        label: 'Student Activation',
                        download:
                          '/pdfs/SustainableFutures-CareerActivation-ProdDevEng.pdf',
                        asLink: true,
                      },
                    ],
                  },
                ]}
              />
            </Column>
          </Row>
        </Container>
      </Section>
    </Layout>
  );
};

export default CareerProfiles;
